import React from "react";
import CommonLayout from "../../layout/CommonLayout";

import * as styles from './style.module.css';
import { ExpandIcon, PDFIcon } from "../../assets/icons";

//PDFs
import diePressePDF from './Die_Presse_article.pdf';
import gazzModenaPDF from './Articolo Lionel Romney.pdf';
import antDagPDF from './Afro-Caribiër gevangen_door_de_nazis.pdf';

//site snippets
import LesFruitsSnipImg from '../../assets/snippets/LesFruitsDeMer_snip.png';
import IshVLibSnipImg from '../../assets/snippets/JewishVirtualLibrary_snip.png';
import SXMSnipImg from '../../assets/snippets/SXM-talks_articleTitle_snip.png';
import TDHOrdealsSnipImg from '../../assets/snippets/TDHOrdeals_snip.png';

//site previews
import LFOralPrevImg from '../../assets/snippets/LesFruitsDeMer_Oral.png';
import LFTellingPrevImg from '../../assets/snippets/LesFruitsDeMer_Telling.png';
import IshVLDupontPrevImg from '../../assets/snippets/JVL_Dupont.png';
import SXMSurvivalPrevImg from '../../assets/snippets/SXM_survival.png';
import TDHOrdealsPrevImg from '../../assets/snippets/TDHOrdeals.png';


//pdf snippets
import DiePressSnipImg from '../../assets/snippets/DiePress_snip.png';
import NellaStoriaSnipImg from '../../assets/snippets/NellaStoria_snip.png';
import AchtergrondSnipImg from '../../assets/snippets/Achtergrond_snip.png';
import ACProjectMuseSnipImg from '../../assets/snippets/AC_ProjectMuse_snip.png';


//pdf previews
import DiePressPrevImg from '../../assets/snippets/DiePress.png';
import NellaStoriaPrevImg from '../../assets/snippets/NellaStoria.png';
import AchtergrondPrevImg from '../../assets/snippets/Achtergrond.png';
import ACProjectMusePrevImg from '../../assets/snippets/AC_ProjectMuse.png';



const Article = (props: any & {type: 'external'|'pdf'}) => {
    var typeIcon = null;
    var typeTitle = null, typeThumbnail = null;
    var br = 0, bi = null
    ;
    switch(props.type) {
        case 'external': typeIcon = <ExpandIcon />; 
            typeTitle = 'extTitle'; typeThumbnail = 'extThumbnail';
            br = 20;
            bi = "linear-gradient(to right, rgba(0,0,0, 0), rgba(0,0,0, 0), rgba(0,0,255, 0.4) )"
            break;
        case 'pdf': typeIcon = <PDFIcon style={{width: 32, height: 32, position: 'relative', top: '7px'}} />; 
            typeTitle = 'pdfTitle'; typeThumbnail = 'pdfThumbnail';
            br = 2; 
            bi = "linear-gradient(to right, rgba(0,0,0, 0), rgba(0,0,0, 0), rgba(255,0,0, 0.4) )";
            break;
    }

    const artStyle = {
        borderRadius: br,
        backgroundImage: bi,
    }

    // const titleStyle = {
    //     color: tc,
    // }

    const thumbnailStyle= {
        clip: props.clip ? "rect(" + props.clip + ")" : null,
    }

    return (
        <a href={props.link} target="_blank" className={styles["artContainer"]} 
            style={artStyle}
        >

            <img className={[styles["artPrevImg"], styles[typeThumbnail]].join(" ")}
                src={props.sitePrevImage} alt={props.alt ? props.alt : "Article preview image"}
            />
            <img className={[styles["artImg"], styles[typeThumbnail]].join(" ")}
                src={props.image} alt={props.sitePrevAlt ? props.alt : "Article thumbnail image"}
                //style={thumbnailStyle}
            />
            

            <div className={styles["artItem"]} >   
                
                <div className={[styles["artTitle"], styles[typeTitle]].join(" ")} >{props.title} {typeIcon}</div>
                <div><i>{props.site}</i></div>
                
                {/* <text className={styles["navItem"]}>
                    {props.link}
                </text> */}
            </div>
        </a>
    );
}
// const Article = (props:any) => {
//     return (
//         <div className={styles["artItem"]}>
//                 <a className={styles["artTitle"]}
//                     href={props.link}
//                 >
//                     {props.title}
//                 </a>
                
//                 <br />
//                 <i>
//                     {props.site}
//                 </i>
                
//                 {/* <text className={styles["navItem"]}>
//                     {props.link}
//                 </text> */}
//         </div>
//     );
// }



const ResourcesPage = () => {

    return (

        <CommonLayout title="Articles in the International Press | An Afro-Caribbean in the Nazi Era">

            <br /><br /><br />
            
            <div className={styles["sites"]}>
            
                <h1>— News and Articles —</h1>
                <br />

               
                 
                <Article title={"Ordeals of Lionel Romney to be narrated in a documentary"}
                    type={'external'}
                    site={"The Daily Herald"} sitePrevImage={TDHOrdealsPrevImg}
                    image={TDHOrdealsSnipImg} //clip={"0%, 100%, 100%, 0%"}
                    link={"https://www.thedailyherald.sx/islands/ordeals-of-lionel-romney-to-be-narrated-in-a-documentary"}
                />
                
                <Article title={"Telling Lionel Romney’s Story"}
                    type={'external'}
                    site={"Les Fruits de Mer"} sitePrevImage={LFTellingPrevImg}
                    image={LesFruitsSnipImg} //clip={"0%, 100%, 100%, 0%"}
                    link={"http://www.lesfruitsdemer.com/telling-lionel-romneys-story/"}
                />

                <Article title={"Oral History Insights"}
                    type={'external'}
                    site={"Les Fruits de Mer"} sitePrevImage={LFOralPrevImg}
                    image={LesFruitsSnipImg} //clip={""}
                    link={"http://www.lesfruitsdemer.com/oral-history-insights/"}
                />

                <Article title={"World War II: The Dupont Mission"}
                    type={'external'}
                    site={"Jewish Virtual Library"} sitePrevImage={IshVLDupontPrevImg}
                    image={IshVLibSnipImg}
                    link={"https://www.jewishvirtuallibrary.org/the-dupont-mission-october-1944-may-1945"}
                />

                <Article title={"Romney's Story of Survival Adds Hope to Remembrance"}
                    type={'external'}
                    site={"SXM Talks"} sitePrevImage={SXMSurvivalPrevImg}
                    image={SXMSnipImg}
                    link={"https://www.sxm-talks.com/the-daily-herald/romneys-story-of-survival-adds-hope-to-remembrance/"}
                />

                {/* <Article title={"Blacks Under the Swastika: A Research Note"}
                    site={"The University of Chicago Press Journals"}
                    link={"https://www.journals.uchicago.edu/doi/10.2307/2668561"}
                /> */}

            </div>
           

            {/* <br /><br /><br /><br /><br /><br /><br /><br /><br /> */}

            <div className={styles["articles"]}>
                
                <h1>— International Articles —</h1>
                <br />

                <Article title={"An Afro-Caribbean in the Nazi Era: The Year in Sint Maarten"}
                    type={'pdf'}
                    site={<text><i>www.doi.org</i>, University of Hawai'i Press 2024</text>}
                    image={ACProjectMuseSnipImg}
                    link={diePressePDF} sitePrevImage={ACProjectMusePrevImg}
                />
                
                <Article title={"Traces of a Father in Mauthausen (German)"}
                    type={'pdf'}
                    site={<text><i>Die Presse</i>, Vienna, Austria 2018</text>}
                    image={DiePressSnipImg}
                    link={diePressePDF} sitePrevImage={DiePressPrevImg}
                />

                <Article title={"Lionel Romney, An Afro-American in Fossoli (Italian)"}
                    type={'pdf'}
                    site={"Gazzetta di Modena"}
                    image={NellaStoriaSnipImg}
                    link={gazzModenaPDF} sitePrevImage={NellaStoriaPrevImg}
                />

                <Article title={"Afro-Caribbean captured by the Nazis (Dutch)"}
                    type={'pdf'}
                    site={"Antilliaans Dagblad, 2021"}
                    image={AchtergrondSnipImg}
                    link={antDagPDF} sitePrevImage={AchtergrondPrevImg}
                />

            </div>
           
            <br /><br /><br />



        </CommonLayout>
    
    );

}

export default ResourcesPage;
