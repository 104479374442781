import React from "react";
import CommonLayout from "../../layout/CommonLayout";

import HTMLFlipBook from "react-pageflip";

import * as styles from './book.module.css';

import introVid from '../../assets/introVid2.mp4';
import bookImg from '../../assets/bookImg.jpg';
import cardImg from '../../assets/indexcard.png';
import recImg from '../../assets/records.png';


const ItemBlock = (props: any) => {
    const img = props.image
        ? <img src={props.image} alt={props.alt ?? "unspecified"} />
        : null
    ;
   
    const caption = props.caption 
        ? <div className={styles['caption']}>
            {props.caption}
        </div>
        :null
    ;

    return(

        <div className={styles["block"]}>
            {img}
            {caption}
        </div>
    );

}

// const MyBook = (props:any) => {
// //     return (
// //       <HTMLFlipBook width={300} height={500} 
// //         className={""} style={undefined} startPage={0} size={"fixed"} minWidth={0} maxWidth={0} minHeight={0} maxHeight={0} drawShadow={false} flippingTime={0} usePortrait={false} startZIndex={0} autoSize={false} maxShadowOpacity={0} showCover={false} mobileScrollSupport={false} clickEventForward={false} useMouseEvents={false} swipeDistance={0} showPageCorners={false} disableFlipByClick={false}>
// //         <div className="demoPage">Page 1</div>
// //         <div className="demoPage">Page 2</div>
// //         <div className="demoPage">Page 3</div>
// //         <div className="demoPage">Page 4</div>
// //       </HTMLFlipBook>
// //     );
// //   }

const Snippet = (props: any) => {
    return(

        <div className={styles["snippet"]}>
            {props.children}
            <br />
            <b>{props.source}</b>
        </div>
    );

}

const BookPage = () => {

    return (

        <CommonLayout title="The Book of his Journey | An Afro-Caribbean in the Nazi Era">
            
            <div className={styles["bookContainer"]}>

            <div className={styles["mediaContainer"]}>
                <video className={styles["bookVid"]} src={introVid}
                    autoPlay={true}
                    controls={true}
                    muted={true}
                />

            </div>
            
            <h2>Read Excerpts from the book...</h2>

            <div className={styles["flipbook"]}>
            <iframe src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=xu949i9zhm"
                width="80%" height="800" seamless="seamless" 
                scrolling="no" frameBorder="0" 
                allowFullScreen allow="autoplay"></iframe>            
            </div>
            
            <span className={styles["bookPreview"]}>
                {/* <img className={styles["bookImg"]} src={bookImg} alt="book" /> */}


                <div className={styles["snippets"]}>
                    {/* <MyBook /> */}
                </div>

                {/* <div className={styles["snippets"]}>
                    <Snippet
                        source={"- p. 144"}
                    > "How did an Afro-Caribbean civilian  merchant sailor become a prisoner in a Nazi concentration camp during World War II?  What did he witness?  How did he survive to tell about it?  This book answers these questions through the story of Lionel Romney, the author’s father.  He was  an Afro-Caribbean (West Indian) who, by chance, was trapped in the politics, chaos, and deadly violence of World War II.  
                    As a non-combatant political prisoner, Lionel Romney spent four years, from 1940 to 1944, in the Italian internment camp system, and the final year of the war, 1944-45, in the notorious Mauthausen concentration camp in Austria.  There, he was subjected to inhumane treatment and near-starvation.  He routinely witnessed atrocities that traumatized him so deeply that he was virtually silent about the experience for over four decades.  After over 20 years of trying, his daughter, Mary, was finally able to get him to talk about it during a series of oral history interviews.  These form the centerpiece of the book, which also chronicles her experience of visiting Mauthausen and Italy after her father passed away.
                    "
                    </Snippet>

                    <Snippet
                        source={"- p. 144"}> "How did an Afro-Caribbean civilian  merchant sailor become a prisoner in a Nazi concentration camp during World War II?  What did he witness?  How did he survive to tell about it?  This book answers these questions through the story of Lionel Romney, the author’s father.  He was  an Afro-Caribbean (West Indian) who, by chance, was trapped in the politics, chaos, and deadly violence of World War II.  
                    As a non-combatant political prisoner, Lionel Romney spent four years, from 1940 to 1944, in the Italian internment camp system, and the final year of the war, 1944-45, in the notorious Mauthausen concentration camp in Austria.  There, he was subjected to inhumane treatment and near-starvation.  He routinely witnessed atrocities that traumatized him so deeply that he was virtually silent about the experience for over four decades.  After over 20 years of trying, his daughter, Mary, was finally able to get him to talk about it during a series of oral history interviews.  These form the centerpiece of the book, which also chronicles her experience of visiting Mauthausen and Italy after her father passed away.
                    "
                    </Snippet>

                    <Snippet
                        source={"- p. 144"}> "How did an Afro-Caribbean civilian  merchant sailor become a prisoner in a Nazi concentration camp during World War II?  What did he witness?  How did he survive to tell about it?  This book answers these questions through the story of Lionel Romney, the author’s father.  He was  an Afro-Caribbean (West Indian) who, by chance, was trapped in the politics, chaos, and deadly violence of World War II.  
                    As a non-combatant political prisoner, Lionel Romney spent four years, from 1940 to 1944, in the Italian internment camp system, and the final year of the war, 1944-45, in the notorious Mauthausen concentration camp in Austria.  There, he was subjected to inhumane treatment and near-starvation.  He routinely witnessed atrocities that traumatized him so deeply that he was virtually silent about the experience for over four decades.  After over 20 years of trying, his daughter, Mary, was finally able to get him to talk about it during a series of oral history interviews.  These form the centerpiece of the book, which also chronicles her experience of visiting Mauthausen and Italy after her father passed away.
                    "
                    </Snippet>

                    <Snippet
                        source={"- p. 144"}> "How did an Afro-Caribbean civilian  merchant sailor become a prisoner in a Nazi concentration camp during World War II?  What did he witness?  How did he survive to tell about it?  This book answers these questions through the story of Lionel Romney, the author’s father.  He was  an Afro-Caribbean (West Indian) who, by chance, was trapped in the politics, chaos, and deadly violence of World War II.  
                    As a non-combatant political prisoner, Lionel Romney spent four years, from 1940 to 1944, in the Italian internment camp system, and the final year of the war, 1944-45, in the notorious Mauthausen concentration camp in Austria.  There, he was subjected to inhumane treatment and near-starvation.  He routinely witnessed atrocities that traumatized him so deeply that he was virtually silent about the experience for over four decades.  After over 20 years of trying, his daughter, Mary, was finally able to get him to talk about it during a series of oral history interviews.  These form the centerpiece of the book, which also chronicles her experience of visiting Mauthausen and Italy after her father passed away.
                    "
                    </Snippet>

                </div> */}

            </span>

            <div className={styles["bookContent"]}>
{/* 
                <ItemBlock image={cardImg} caption=
                    "The front of an index card from the official Nazi records, dated June 24, 1944; this is another of Lionel Romney’s  KZM records.  His prisoner number, 76548, is consistent with all his other records.  English translations:"
                />

                <p>
                    It is significant to note that his race is listed.  This racial classification of Black prisoners was inconsistent throughout the concentration camp system.  
                </p>

                <p>Museum Auschwitz-Birkenau, Sygn. D Mau.3/45782.</p>

                <br /> */}

                {/* <ItemBlock image={recImg} caption=
                    "Official records from Mauthausen concentration camp in Austria."
                /> */}
                </div>
            </div>


        </CommonLayout>
    
    );

}

export default BookPage;
