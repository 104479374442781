import { Link } from 'gatsby';
import React from 'react';
import Nav from '../components/Nav';

import * as stdStyles from './CommonLayout.module.css';
import * as mobStyles from './CommonLayout.mobile.module.css';

import hillsideImg from '../assets/hillside.jpg';
import underTreesImg from '../assets/underTrees.jpg';
import { getLayoutType, LAYOUT } from './common';
import { render } from 'react-dom';
import { Helmet } from 'react-helmet';




type IDeviceLayout = {
    [key in LAYOUT]: { style: any; };
};

let DeviceLayout: IDeviceLayout = {
    [LAYOUT.mobile]: {style: mobStyles},
    [LAYOUT.tablet]: {style: mobStyles},
    [LAYOUT.desktop]: {style: stdStyles}
};
// DeviceLayout[LAYOUT.mobile] = {style: mobStyles}
// DeviceLayout[LAYOUT.tablet] = {style: mobStyles} //TODO: Impl. Tablet style
// DeviceLayout[LAYOUT.desktop] = {style: stdStdyles}



const Header = (props: any) => 
    <div className={props.styles["headerContainer"]}>
        <div className={props.styles["headerContent"]}>

            <div className={props.styles["banner"]}>
                <img className={props.styles["bannerBG"]} src={hillsideImg} alt="hill" />
                
                <h1 className={props.styles["titleName"]}>An Afro-Caribbean in the Nazi Era</h1>
                <Nav />
            </div>

        </div>
    </div>
;


const Footer = (props: any) => 
    <div className={props.styles["footerContainer"]}>
        <div className={props.styles["footerContent"]}>

            <hr />
            <div className={props.styles["footerCopyright"]}>© 2022 Mary Romney-Schaab.</div>

        </div>
    </div>
;

const Body = (props: any) => 
    <div className={props.styles["bodyContainer"]}>
        <div className={props.styles["bodyContent"]}>

            <img className={props.styles["bodyBG"]} src={underTreesImg} alt="hill" />

            {props.children}

        </div>
    </div>
;

interface CommonLayoutProps {
    /*Title of page*/
    title: string
}

interface CommonLayoutState {
    layout: any;
}

class CommonLayout extends React.Component<CommonLayoutProps&{}, CommonLayoutState> {

    constructor(props:any) {
        super(props);
        this.state = {
            layout: DeviceLayout[LAYOUT.desktop]//[getLayoutType()]
        }
    }

    

    render() {
        const styles = this.state.layout.style;

        return (
            <main className={styles["layoutMain"]}>
                <Helmet>
                    <title>{this.props.title}</title>
                </Helmet>
                
                <div className={styles["layoutContent"]}>


                    <Header styles={styles} />

                    <Body styles={styles}
                        children={this.props.children} 
                    />

                    <Footer styles={styles} />


                </div>
            </main>
        );
    }
}


export default CommonLayout;