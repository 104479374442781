import React, { useState } from "react";
import CommonLayout from "../../layout/CommonLayout";
import emailjs from '@emailjs/browser';

import * as styles from './contact.module.css';





const SERVICE_ID = "service_i4kuqj7";
const USER_ID = "A6-Pe0Va3AOUORg7k";

const CONTACT_TEMPLATE_ID = "template_nresjpk";
const EVENT_TEMPLATE_ID = ""//"template_tzebzul";

const to_name_def = "Mary";



const Req = (props: any) => <p style={
        {color: "red", fontSize: 24, fontWeight: "bold"}
    }>*</p>
;


const ContactForm = (props: any) => {
    return (
        <form className={styles["basicContactForm"]}
                 onSubmit={props.onSubmit}>

                     <h2>Contact the Author</h2>
                    
                    <label>Name:
                    <input name="conName" className={styles["conName"]} type="text" required />
                    </label>

                    <FlexRow>
                        <label>Email:
                        <input name="conEmail" className={styles["conEmail"]} type="email" required />
                        </label>

                        <label>Phone:
                        <input name="conPhone" className={styles["conPhone"]} type="tel" required />
                        </label>
                    </FlexRow>
                    

                    <label>Organization:
                    <input name="conOrg" className={styles["conOrg"]} type="text" />
                    </label>

                    <label>Subject:
                    <input name="conSubject" className={styles["conSubject"]} type="text" required />
                    </label>

                    <label>Message
                    <br />
                    <textarea name="conMessage" className={styles["conMessage"]}  required/>
                    </label>

                    <input className={styles["butSubmit"]}  name="conSubmit" type="submit" />

                </form>
    );
}

const FlexRow = (props:any) => <div className={styles["flexRow"]} {...props} >
    {props.children}
</div>
;

const FlexCol = (props:any) => <div className={styles["flexCol"]} {...props} >
    {props.children}
</div>
;

const TimezoneSelect = (props:any) => 
<select className={styles["inputOrgTimezoneSelect"]} {...props}>
    <option >(GMT-12:00) International Date Line West</option>
    <option >(GMT-11:00) Midway Island, Samoa</option>
    <option >(GMT-10:00) Hawaii</option>
    <option >(GMT-09:00) Alaska</option>
    <option >(GMT-08:00) Pacific Time (US & Canada)</option>
    <option >(GMT-08:00) Tijuana, Baja California</option>
    <option >(GMT-07:00) Arizona</option>
    <option >(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
    <option >(GMT-07:00) Mountain Time (US & Canada)</option>
    <option >(GMT-06:00) Central America</option>
    <option >(GMT-06:00) Central Time (US & Canada)</option>
    <option >(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
    <option >(GMT-06:00) Saskatchewan</option>
    <option >(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
    <option >(GMT-05:00) Eastern Time (US & Canada)</option>
    <option >(GMT-05:00) Indiana (East)</option>
    <option >(GMT-04:00) Atlantic Time (Canada)</option>
    <option >(GMT-04:00) Caracas, La Paz</option>
    <option >(GMT-04:00) Manaus</option>
    <option >(GMT-04:00) Santiago</option>
    <option >(GMT-03:30) Newfoundland</option>
    <option >(GMT-03:00) Brasilia</option>
    <option >(GMT-03:00) Buenos Aires, Georgetown</option>
    <option >(GMT-03:00) Greenland</option>
    <option >(GMT-03:00) Montevideo</option>
    <option >(GMT-02:00) Mid-Atlantic</option>
    <option >(GMT-01:00) Cape Verde Is.</option>
    <option >(GMT-01:00) Azores</option>
    <option >(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
    <option >(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
    <option >(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
    <option >(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
    <option >(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
    <option >(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
    <option >(GMT+01:00) West Central Africa</option>
    <option >(GMT+02:00) Amman</option>
    <option >(GMT+02:00) Athens, Bucharest, Istanbul</option>
    <option >(GMT+02:00) Beirut</option>
    <option >(GMT+02:00) Cairo</option>
    <option >(GMT+02:00) Harare, Pretoria</option>
    <option >(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
    <option >(GMT+02:00) Jerusalem</option>
    <option >(GMT+02:00) Minsk</option>
    <option >(GMT+02:00) Windhoek</option>
    <option >(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
    <option >(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
    <option >(GMT+03:00) Nairobi</option>
    <option >(GMT+03:00) Tbilisi</option>
    <option >(GMT+03:30) Tehran</option>
    <option >(GMT+04:00) Abu Dhabi, Muscat</option>
    <option >(GMT+04:00) Baku</option>
    <option >(GMT+04:00) Yerevan</option>
    <option >(GMT+04:30) Kabul</option>
    <option >(GMT+05:00) Yekaterinburg</option>
    <option >(GMT+05:00) Islamabad, Karachi, Tashkent</option>
    <option >(GMT+05:30) Sri Jayawardenapura</option>
    <option >(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
    <option >(GMT+05:45) Kathmandu</option>
    <option >(GMT+06:00) Almaty, Novosibirsk</option>
    <option >(GMT+06:00) Astana, Dhaka</option>
    <option >(GMT+06:30) Yangon (Rangoon)</option>
    <option >(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
    <option >(GMT+07:00) Krasnoyarsk</option>
    <option >(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
    <option >(GMT+08:00) Kuala Lumpur, Singapore</option>
    <option >(GMT+08:00) Irkutsk, Ulaan Bataar</option>
    <option >(GMT+08:00) Perth</option>
    <option >(GMT+08:00) Taipei</option>
    <option >(GMT+09:00) Osaka, Sapporo, Tokyo</option>
    <option >(GMT+09:00) Seoul</option>
    <option >(GMT+09:00) Yakutsk</option>
    <option >(GMT+09:30) Adelaide</option>
    <option >(GMT+09:30) Darwin</option>
    <option >(GMT+10:00) Brisbane</option>
    <option >(GMT+10:00) Canberra, Melbourne, Sydney</option>
    <option >(GMT+10:00) Hobart</option>
    <option >(GMT+10:00) Guam, Port Moresby</option>
    <option >(GMT+10:00) Vladivostok</option>
    <option >(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
    <option >(GMT+12:00) Auckland, Wellington</option>
    <option >(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
    <option >(GMT+13:00) Nuku'alofa</option>
</select>

const EventRequestForm = (props:any) => {
    const onChange = props.onChange;

    return (
        <form className={styles["eventRequestForm"]}
            onSubmit={props.onSubmit}
        >
            <h2>Request a Presentation</h2>

            <p className={styles["reqSummary"]}>
                Mary Romney-Schaab regularly gives Zoom and in-person presentations about her book.  To request a presentation, please provide the information below.  Mary Romney-Schaab will contact you shortly after you submit the following information.
            </p>

            <div className={styles["perSec"]} >
                <h3 className={styles["perTitle"]} >Person of contact</h3>   
                
                <FlexRow>
                    <div>
                    <label>First name
                        <input name="perFirstname" onChange={onChange} type="text" required />
                    </label>
                    </div>

                    <div>
                    <label>Last name
                        <input name="perLastname" onChange={onChange} type="text" required />
                    </label>
                    </div>
                </FlexRow>
                
                <FlexRow>
                    <div>
                    <label>Email
                        <input name="perEmail" onChange={onChange} type="email" required />
                    </label>
                    </div>

                    <label>Country Code
                        <input name="" onChange={onChange} className={styles["inputPerCountryCode"]} type="number"/>
                    </label>

                    <div>
                    <label>Phone
                        <input name="perPhone" onChange={onChange} type="tel" />
                    </label>
                    </div>

                    
                </FlexRow>
            </div>
            
            


            <br /> <br />
            {/*  Organiztion Details  */}
            <div>

                
            </div>
            <div className={styles["orgSec"]} >
            <h3 className={styles["orgTitle"]}>Organization</h3>   
            
                <label>Name of organization
                    <input name="orgName" onChange={onChange} className={styles["inputOrg"]} type="text"/>
                </label>
                
                <FlexRow>
                    <label>Website
                        <input name="orgWebsite" onChange={onChange} className={styles["inputOrg"]} type="url"/>
                    </label>

                    <label>Phone
                        <input name="orgPhone"  onChange={onChange} className={styles["inputOrg"]} type="tel"/>
                    </label>

                </FlexRow>
                
                
                <FlexCol>
                    <label>Address
                        <input name="orgAddress"  onChange={onChange} className={styles["inputOrg"]} type="text"/>
                    </label>
                    
                    <FlexRow>
                        <label>City/Town
                            <input name="orgCity"  onChange={onChange} className={styles["inputOrg"]} type="text"/>
                        </label>
                        
                        <label>State/Province
                            <input name="orgState"  onChange={onChange} className={styles["inputOrg"]} type="text"/>
                        </label>

                        <label>Country
                            <input name="orgCountry" onChange={onChange} className={styles["inputOrg"]} type="text"/>
                        </label>

                    </FlexRow>
                    
                    
                    
                </FlexCol>
               
                

                
                
                

                <FlexRow>
                    <label>Oragnization Email
                        <input name="orgEmail" onChange={onChange} className={styles["inputOrg"]} type="email"/>
                    </label>

                    
                </FlexRow>

                <FlexRow>
                    <label>Oragnization Type
                        <input name="orgType" onChange={onChange} className={styles["inputOrg"]} type="text"/>
                    </label>
                </FlexRow>
                    

               

               
                    
                    

                    
                    <label>Mission of Organization
                        <textarea name="orgMission" className={styles["inputOrgMissionBox"]} />
                    </label>
                    
               


                <br />
                <h3 className={styles["presTitle"]} >Presentation</h3>  

                <FlexRow>
                    <label>Desired date and time (at least 2 options)</label>
                </FlexRow>
                
                <FlexRow>
                    <label>1st Option
                        <input onChange={onChange} className={styles["inputOrg"]} name="date1" type="date"/>
                    </label>
                
                    <label> Local Time
                        <input onChange={onChange} className={styles["inputOrg"]} name="time1" type="time"/>
                    </label>

                    <label> Your Time Zone
                        <TimezoneSelect name="zone1" />
                    </label>
                </FlexRow>

                <FlexRow>
                    <label>2nd Option
                        <input onChange={onChange} className={styles["inputOrg"]} name="date2" type="date"/>
                    </label>
                
                    <label> Local Time
                        <input onChange={onChange} className={styles["inputOrg"]} name="time2" type="time"/>
                    </label>

                    <label> Your Time Zone
                        <TimezoneSelect name="zone2" />
                    </label>
                </FlexRow>

                <FlexRow>
                    <label>3rd Option
                        <input onChange={onChange} className={styles["inputOrg"]} name="date3" type="date"/>
                    </label>
                
                    <label> Local Time
                        <input onChange={onChange} className={styles["inputOrg"]} name="time3" type="time"/>
                    </label>

                    <label> Your Time Zone
                        <TimezoneSelect name="zone3" />
                    </label>
                </FlexRow>

                {/* <FlexRow>
                    <label>1st Option
                        <input onChange={onChange} className={styles["inputOrg"]} name="date1" type="date"/>
                    </label>
                    
                    <label>2nd Option
                        <input onChange={onChange} className={styles["inputOrg"]} name="date2" type="date"/>
                    </label>
                    
                    <label>3rd Option
                        <input onChange={onChange} className={styles["inputOrg"]} name="date3" type="date"/>
                    </label>
                </FlexRow>
                
                <FlexRow>
                    <label>
                        <input onChange={onChange} className={styles["inputOrg"]} name="time1" type="time"/>
                    </label>
                    
                    <label>
                        <input onChange={onChange} className={styles["inputOrg"]} name="time2" type="time"/>
                    </label>
                    
                    <label>
                        <input onChange={onChange} className={styles["inputOrg"]} name="time3" type="time"/>
                    </label>
                </FlexRow> */}

                <FlexRow>
                <label>Audience
                    <select name="orgAudience" defaultValue={"ph"} className={styles["inputOrg"]}>
                        <option value="ph" disabled>Please select an audience</option>

                        <option value="Secondary School">Secondary School</option>
                        <option value="University">University</option>
                        <option value="Community Organization">Community Organization</option>
                        <option value="Non-profit">Non-profit</option>
                        <option value="Press">Press</option>
                        <option value="Public">Public</option>
                        <option value="Other">Other</option>
                    </select>
                
                </label>

                <label>Projected size
                    <input name="orgAudienceSize" className={styles["inputOrgAudienceSize"]} onChange={onChange} type="number" />
                </label>
                </FlexRow>

                
                    
                <FlexRow>
                    <label className={styles["labelOrgRadio"]} > 
                        <input name="orgRadioLive" onChange={onChange} className={styles["inputOrgRadio"]} type="radio" 
                        value={"Live"}/>Live
                    </label>
                        
                    <label className={styles["labelOrgRadio"]} >
                        <input name="orgRadioLive" onChange={onChange} className={styles["inputOrgRadio"]} type="radio" 
                        value={"Virtual"} />Virtual (Zoom, WebEX, etc.)
                    </label>
                </FlexRow>
                
                <br />
                <br />
                

                <label>Occasion
                    <input name="orgOccasion" onChange={onChange} type="text" placeholder="e.g. Black History Month, etc"/>
                </label>
                
                <br />
                <label>Add any additional details below:
                    <textarea name="orgAdditionalDetails" className={styles["inputOrgDetBox"]} />
                </label>
                    
                    

                <input name="orgSubmit" onChange={onChange} className={styles["butSubmit"]} //className={styles["eventSubmit"]} 
                type={'submit'} />


            </div>

        </form>
    );
}



const ConfirmationBox = (props:any) => {
    return (
        <div className={styles["confirmRegion"]} >
            {/* <div className={styles["darkenedBG"]} /> */}

            <div className={styles["confirmBox"]} style={{top: props.y, left: props.x}} >
                <div className={styles["confirmMessage"]} >
                    {props.message}
                </div>
                <button className={styles["confirmButOK"]} onClick={props.onConfirm}>OK</button>
            </div>
        </div>
    );
}

class ContactPage extends React.Component <any, any> {

    // const [name, setName]   = useState(0);
    // const [email, setEmail] = useState(0);
    // const [Org, setOrg]     = useState(0);
    // const [phone, setPhone] = useState(0);

    // const [subject, setSubject] = useState(0);
    // const [message, setMessage] = useState(0);

    constructor(props:any) {
        super(props);
        
        this.state = {
            contactConfirm: false, //TODO: set to false
            confirmMessage: "Your message has been sent. Thank you!",
            confirmPos: {x: 750, y: 1500},
        };
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const name = target.name;
        const id = target.id;
        const value = target.value;

        this.setState(
            {
                [name]: value
            }
        );

        console.log("Change: [" + name + "] changed to: " + value);
    }

    handleContactSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        //console.log("Sending email...");
        
        event.preventDefault();
        const res = this.sendContactEmail({}, event);
        if (res) this.contactConfirmation(event);
        event.currentTarget.reset();
    }

    handleEventSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    }

    handleConfirm = () => {
        this.setState({contactConfirm: false});
    }

    private addContactToDB = (data:any) => {

    }

    private addEventToDB = (data: any) => {

    }

    private sendContactEmail = (info: any, event: React.FormEvent<HTMLFormElement>): number => {
        return this.sendEmail('contact', event);
    }

    private sendEventEmail = (info: any, event: React.FormEvent<HTMLFormElement>) => {
        return this.sendEmail('event', event);
    }

    private sendEmail = (type: 'contact'|'event', event: React.FormEvent<HTMLFormElement>): number => {
        var templateID = "";
        
        switch(type){
            default:
            case 'contact': templateID = CONTACT_TEMPLATE_ID;
                break;
            case 'event': templateID = EVENT_TEMPLATE_ID; //TODO: Impl. in EmailJS
                break;
        }

        //console.log(event.currentTarget);
        
        emailjs.sendForm(SERVICE_ID, templateID, event.currentTarget, USER_ID)
        .then( (result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        })
        ;

        //console.log("Email sent!")

        return 1;
    }

    private contactConfirmation = (event: React.FormEvent<HTMLFormElement>) => {
        this.setState({ 
            contactConfirm: true ,
            confirmPos: {x: 64, y: 450},
        });
    }

    private eventConfirmation = (event: React.FormEvent<HTMLFormElement>) => {
        this.setState({ 
            eventConfirm: true,
            confirmPos: {x: 64, y: 450},
        });
    }

    
    render() {
        return (

            <CommonLayout title="Contact Us | An Afro-Caribbean in the Nazi Era">

                <div className={styles["contactBody"]}>
                        
                        {!this.state.contactConfirm ? null
                            : <ConfirmationBox x={this.state.confirmPos.x} y={this.state.confirmPos.y}
                            message={this.state.confirmMessage} onConfirm={this.handleConfirm}/>
                        }

                        <div className={styles["commonForms"]}>
                            <ContactForm onChange={this.handleChange} onSubmit={this.handleContactSubmit} />
                            <EventRequestForm onChange={this.handleChange} onSubmit={this.handleEventSubmit}/>
                        </div>

                </div>

            </CommonLayout>
        
        );
    }

}

export default ContactPage;
