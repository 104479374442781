import React from "react";
import CommonLayout from "../../layout/CommonLayout";

import * as styles from './style.module.css';

import int1 from '../../assets/int1.png'
import int2 from '../../assets/int2.png';
import int3 from '../../assets/int3.png';


const IntSeg = (props: any) => {
    const img = props.image
        ? <img src={props.image} alt={props.alt ?? "unspecified"} />
        : null
    ;
   
    const text = props.text 
        ? <div className={styles['segText']}>
            {props.text}
        </div>
        :null
    ;

    return(

        <div className={styles["intSeg"]}>
            {img}
            {text}
        </div>
    );

}

const InterviewPage = () => {

    return (

        <CommonLayout title="Pieces of the Interview | An Afro-Caribbean in the Nazi Era">
            <br />
            <h1>Pieces of the Oral History (Interview)</h1>
            <br />
            <br />
            
            <div className={styles["container"]}>
            <div className={styles["content"]}>

            <IntSeg
                image={int1}
                text={<>
                    <h2>Mary Romney:</h2> 
                    <p><b>Where were you when the war ended?</b></p>
                    
                    <br />

                    <h1>Lionel Romney:</h1> 
                    <p>"I was up in a place.  It’s near to Gratz. A concentration camp.  One of those notorious concentration camps.  Mauthausen.  It’s up on a hill.  And they had the crematoriums, and the camps, you know, the barracks-like thing, you know.  So many people in a barracks.  
                    </p>
                    <p>"We were liberated in 1945.  The 10th of May.  About a couple of days after . . .  before Hitler died.""
                    </p>
                </>}
            />
            <IntSeg
                image={int2}
                text={<>
                    <h2>MR:</h2> 
                    <p><b>Were you ever in any danger?</b></p>
                    
                    <br />

                    <h1>LR:</h1> 
                    <p>"Every day was dangerous.  There wasn’t a day that wasn’t. Sometimes I said <i>“How the hell I going to get out of here?”</i>  And then we heard different things of the atrocities that the Germans was committing in the camps, like before they leave, they kill everybody, you see, if they had to run away."  
                    </p>
                </>}
            />
            <IntSeg
                image={int3}
                text={<>

                    <br />
                    <h1>LR:</h1> 
                    <p>"The most fearful part was in the night . . . when the SS man would come . . .  and call out a number.  Siebentausend siebenhundert. . . aufstehen. [seven thousand seven hundred    . . . get up].  
                    </p>
                    <p>"You was frightened like hell because you don’t know when he’s going to call your number.  He take the kid out, and you don’t see him anymore."
                    </p>
                </>}
            />
                
            </div>
            </div>


        </CommonLayout>
    
    );

}

export default InterviewPage;
