import React from "react";
import CommonLayout from "../../layout/CommonLayout";

import * as styles from './style.module.css';



const UniversityInfoPage = () => {

    return (

        <CommonLayout title="Info for Universities | An Afro-Caribbean in the Nazi Era">
            



        </CommonLayout>
    
    );

}

export default UniversityInfoPage;
