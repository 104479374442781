import React from "react";
import ImageGallery from 'react-image-gallery';
import CommonLayout from "../../layout/CommonLayout";
import Carousel from "nuka-carousel";
import ProtoCarousel from "../../components/gallery/Carousel";
//import ProGallery from 'pro-gallery';
import { Carousel as ReCarousel } from 'react-responsive-carousel';


import * as styles from './style.module.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 


import ovensImg from '../../assets/ovens.jpg';
import gateImg from '../../assets/gate.jpg';
import ishMonuImg from '../../assets/ishMonu.jpg';
import pathImg from '../../assets/path.jpg';
import executionImg from '../../assets/execution.jpg';
import execImg from '../../assets/exec.jpg';
import statueImg from '../../assets/statue.jpg';
import underTreesImg from '../../assets/underTrees.jpg';
import { CItem } from "../../components/gallery/Carousel/Carousel";

import site1 from '../../assets/gallery/site1.jpg';
import site2 from '../../assets/gallery/site2.jpg';
import site3 from '../../assets/gallery/site3.jpg';
import site4 from '../../assets/gallery/site4.jpg';
import site5 from '../../assets/gallery/site5.jpg';

const images = [
    { original: ovensImg, 
        originalWidth: 700,
        originalHeight: 400,

        thumbnail:ovensImg ,
        thumbnailWidth: 60,
        thumbnailHeight: 40,
    },
    { original: gateImg, 
        originalWidth: 700,
        originalHeight: 400,

        thumbnail:gateImg,
        thumbnailWidth: 60,
        thumbnailHeight: 40,
    },
    { original: ishMonuImg, 
        originalWidth: 700,
        originalHeight: 400,

        thumbnail: ishMonuImg ,
        thumbnailWidth: 60,
        thumbnailHeight: 40,
    },
    { original: pathImg, 
        originalWidth: 700,
        originalHeight: 400,

        thumbnail: pathImg ,
        thumbnailWidth: 60,
        thumbnailHeight: 40,
    },
    { original: executionImg, 
        originalWidth: 700,
        originalHeight: 400,

        thumbnail:executionImg ,
        thumbnailWidth: 60,
        thumbnailHeight: 40,
    },
    { original: execImg, 
        originalWidth: 700,
        originalHeight: 400,

        thumbnail: execImg ,
        thumbnailWidth: 60,
        thumbnailHeight: 40,
    },
    { original:  statueImg, 
        originalWidth: 700,
        originalHeight: 400,

        thumbnail: statueImg ,
        thumbnailWidth: 60,
        thumbnailHeight: 40,
    },
];


class GalleryItems {
    items: any[];
    constructor ( items: any[] ) {
        this.items = items;
    }

    addItem(img: any) {
        const item = { 
            original:  img, 
            originalWidth: 700,
            originalHeight: 400,
    
            thumbnail: img ,
            thumbnailWidth: 60,
            thumbnailHeight: 40,
        }

        this.items.push(item);
    }

    getItems() {
        return this.items;
    }
}

var galleryItems = new GalleryItems(images);

galleryItems.addItem(underTreesImg)
// const images = [
//     <img src={ovensImg} alt="image" />,
//     <img src={gateImg} alt="image" />,
//     <img src={ishMonuImg} alt="image" />,
//     <img src={pathImg} alt="image" />,
//     <img src={executionImg} alt="image" />,
//     <img src={execImg} alt="image" />,
//     <img src={ statueImg} alt="image" />,
// ]




// const items = [
//     { // Image item:
//         itemId: 'i1',
//         mediaUrl: 'https://i.picsum.photos/id/674/200/300.jpg?hmac=kS3VQkm7AuZdYJGUABZGmnNj_3KtZ6Twgb5Qb9ITssY',
//         metaData: {
//             type: 'image',
//             height: 200,
//             width: 100,
//             title: '',
//             description: '',
//             focalPoint: [0, 0],
//             link: {
//                 url: 'http://example.com',
//                 target: '_blank'
//             },
//         }
//     },
// ]



const LocationPage = () => {

    return (

        <CommonLayout title="Photos of the Location | An Afro-Caribbean in the Nazi Era">
            
            <div className={styles["locationContainer"]}>
                
                <h3>Images of the </h3>
                <h2>
                   Mauthausen Concentration Camp Museum
                </h2>
                <h4>(Austria)</h4>

                {/* <Carousel
                    className={styles["gallery"]} 
                >

                    {images}
                    
                </Carousel> */}

                {/* <ImageGallery 
                    //className={styles["gallery"]} 
                    items={galleryItems.getItems()} /> */}

                {/* <ProtoCarousel>
                    <CItem imgSrc={ovensImg} />
                </ProtoCarousel> */}

                <ReCarousel
                    width={"90%"}
                    
                >

                    <div>
                        <img src={ovensImg} />
                    </div>
                    
                    <div>
                        <img src={gateImg} />
                    </div>

                    <div>
                        <img src={ishMonuImg} className={styles["reItem"]} />
                    </div>

                    <div>
                        <img src={pathImg} />
                    </div>
                    
                    <div>
                        <img src={execImg} />
                    </div>
                    
                    <div>
                        <img src={statueImg} className={styles["reItem"]} />
                    </div>
                    
                    <div>
                        <img src={site1} />
                    </div>
                    
                    <div>
                        <img src={site2} />
                    </div>
                    
                    <div>
                        <img src={site3} />
                    </div>
                    
                    <div>
                        <img src={site4} />
                    </div>
                    
                    <div>
                        <img src={site5} />
                    </div>


                </ReCarousel>



            </div>



        </CommonLayout>
    
    );

}

export default LocationPage;
